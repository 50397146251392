<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="footerBar">
        <div class="footerBar-top">
            <div class="left">
                <img class="logo" src="@/assets/img/logo.png" alt="">
                <p>Ship Smart. Save Big. It's not just shipping - it's ShipsCheap.</p>
                <p>
                    <a href="https://twitter.com/shipscheap" target="_blank">
                        <svg class="img" viewBox="0 0 24 24" aria-hidden="true"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
                    </a>
                    <a href="https://www.facebook.com/people/ShipsCheap/100069626704131/" target="_blank">
                        <img class="img" src="@/assets/img/facebook.png" alt="">
                    </a>
                    <a href="https://www.youtube.com/@shipscheap" target="_blank">
                        <img class="img" src="@/assets/img/youtobe.png" alt="">
                    </a>
                    <a href="https://linkedin.com/company/shipscheap" target="_blank">
                        <img class="img" src="@/assets/img/in.png" alt="">
                    </a>
                </p>
            </div>
            <div class="right">
                <div class="list">
                    <b>{{ $t('about') }}</b>
                    <ul>
                        <li @click="toPage('/aboutus')">{{ $t('company') }}</li>
                        <li @click="toPage('/partners')">{{ $t('careers') }}</li>
                        <li @click="toPage('/contactus')">{{ $t('comtactUs') }}</li>
                    </ul>
                </div>
                <div class="list">
                    <b>{{ $t('support') }}</b>
                    <ul>
                        <li>{{ $t('blogsOrNews') }}</li>
                        <li @click="toPage('/service')">{{ $t('helpCenter') }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footerBar-bottom">
            Copyright © 2024 ShipsCheap
        </div>
    </div>
</template>

<script setup>
// import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
function toPage (path) {
  console.log(route.path)
  window.scrollTo(0, 0)
  router.push(path)
}
</script>

<style lang="scss" scoped>
.footerBar{
    // min-width: 100vh;
    width: 100%;
    padding: 1.875rem;
    padding-right: 3.625rem;
    box-sizing: border-box;
    .footerBar-top{
        display: flex;
        justify-content: space-between;
        .left{
            .logo{
                width: 11.875rem;
                margin-bottom: 1.125rem;
            }
            p{  
                margin-bottom: 2.5rem;
                display: flex;
                a{
                    background-color: #F4F7F9;
                    border-radius: .25rem;
                    width: 2.25rem;
                    height: 2.25rem;
                    cursor: pointer;
                    margin-right: .75rem;
                    box-sizing: border-box;
                    padding: .625rem .5rem;
                    line-height: 2.25rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .img{
                    height: 100%;
                    font-weight: 800;
                    // width: 100%;
                }
            }
        }
        .right{
            display: flex;
            .list{
                &:first-child{
                    margin-right: 5rem;
                }
                b{
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                }
                ul{
                    margin-top: 1.25rem;
                    li{
                        list-style: none;
                        font-size: 1rem;
                        margin-bottom: 1.125rem;
                        cursor: pointer;
                        color: #6E7191;
                    }
                }
            }
        }
    }
}
@media (max-width: 900px){
    .footerBar{
        .footerBar-top{
            flex-direction: column;
            .left{
                p{
                    margin-bottom: 1.25rem;
                }
            }
            .right{
                flex-direction: column;
                .list{
                    b{
                    line-height: 1.25rem;
                    }
                    ul{
                        margin-top: .625rem;
                        li{
                            float: left;
                            margin-right: .9375rem;
                            margin-bottom: 1.25rem;
                            &:last-child{
                                margin-right: 0rem;
                            }
                        }
                    }
                }
            }
        } 
    }
}
</style>