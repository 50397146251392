const text = {
  solution:['solution', '解决方案'],
  pricingPlan: ['pricing plan', '价格方案'],
  partner: ['partner', '合作伙伴'],
  Login: ['Login', '登录'],
  SignUpForFree: ['Sign Up for Free', '免费注册'],
  ForStartupsTitle: ['For Startups - Starter', '个人小规模邮寄 - Starter'],
  ForStartupsText1: ['Rate calculator', '折扣运费对比'],
  ForStartupsText2: ['Buy & Print labels', '打印折扣运单'],
  ForStartupsText3: ['USPS QR codes', 'USPS二维码运单'],
  ForStartupsText4: ['Store integrations', '店铺对接'],
  PricingPlanTitle: ['Pricing Plan', '价格方案'],
  PricingPlanText1: ['Free to try', '免费使用'],
  RateCalculatorTitle: ['Rate Calculator', '运费计算器'],
  RateCalculatorText1: ['Get the best USPS', '获取最优的USPS、UPS价格'],
  CarrierPartnetTitle: ['Carrier Partnet', '承运商'],
  CarrierPartnetText1: ['Reliableglobal shipping solutions & save up to 89% off', '可靠的全球运输解决方案，节省高达89%off的费用'],
  EcommercePartnerTitle: ['Ecommerce Partner', '电商平台'],
  EcommercePartnerText1: ['Seamless integration for online retailer to manage orders', '为线上零售商提供店铺无缝集成，方便管理订单'],
  Home: ['Home', '首页'],
  shipMore: ['Ship more.', 'ShipsCheap'],
  shipFaster: ['Ship faster.', '集成化打单发货'],
  shipEverywhere: ['Ship everywhere.', '让电子商务更简单'],
  AllInOneShippingText: ['All-in-one shipping solution offering up to 89% off on rates, effortless integrations. No fees, no constraints. Elevate your shipping today!', '$0月费物流解决方案  - 无缝对接各大电商平台，自动同步订单，批量打印发货，立享2折运费！'],
  signUpFree: ['Sign Up Free', '创建您的免费账户'],
  shipLikeAPro: ['SHIP LIKE A PRO', '平台功能优势'],
  shipLikeAProText:['We have the best solution for you','选择合适的运输服务其实很简单'],
  shipLikeAPro1: ['Unlock shipping discounts of up to 89% off', '解锁高达89%off的运输折扣'],
  shipLikeAPro1Text1: ['Maximize savings with ShipsCheap: Get deep discounts on USPSrates, expanding your shipping options. ', '与ShipsCheap一同享受更多节省：我们为您提供USPS的超值折扣价格，丰富您的货运选择并帮助您降低成本%off的运输折扣'],
  shipLikeAPro1Text2: ['Start savings today!', '不再等待，今天就开始为您的业务省钱吧！'],
  shipLikeAPro2: ['Enable top-tier shipping solutions for all sizes', '为各类型用户提供解决方案'],
  shipLikeAPro2Text1: ['Choosing the right shipping strategy?', '正在选择适合的货运策略？'],
  shipLikeAPro2Text2: ['Try ShipsCheap for all your shipping needs, personal or business. Explore our plans. From a single package to thousands, we ensure the best rates.', 'ShipsCheap满足您的所有货运需求，无论是个人、小企业还是大型公司。查看我们的服务方案，从单个包裹到大批量发货，我们都保证提供最佳的价格。'],
  shipLikeAPro3: ['Get all carriers & stores integrated in one place, no coding required', '一键整合所有承运商和店铺'],
  shipLikeAPro3Text1: ['Effortlessly integrate carriers & store in a click.', '无需任何编码技能，您就可以轻松地将所有承运商和在线商店集成到我们的平台上。'],
  shipLikeAPro3Text2: ['Seamlessly manage shipping and sales activities with user-friendly tools, saving time and energy.', '利用我们直观易用的工具，您可以高效地管理货运和销售，确保每一个步骤都流畅进行，大大节省您的时间和精力。'],
  testimonials: ['TESTIMONIALS', 'ShipsCheap用户评价'],
  testimonialsText: ['Hear what our clients say', '数以万计的用户选择ShipsCheap完成邮寄流程'],
  testimonials1Text: ['Whenever I ship I love going to ShipsCheap, I have used for three years, and it saved me a lot of time. It is very fast and convenient, it helps give me the price so quickly.', '每当我发货时，我都喜欢使用ShipsCheap平台，我已经用了三年了，它为我节省了很多时间。平台操作非常流畅，也很方便，它能帮助我很快地获得运费报价。'],
  testimonials2Text: ['Easy to use, B2C integration, brings great convenience. I recommend this to everyone and think everyone should use it. It saves you time and money. Customer service is also very helpful.', 'ShipsCheap系统易于使用，B2C整合，带来极大的便利。我向大家推荐这个平台，并认为每个人都应该使用它。它为你节省了时间和金钱。客户服务也很有帮助。'],
  testimonials3Text: ['Excellent UI interface. Minimalistic and functional. Responsive customer service - strong execution, no BS. Since I switched to ShipsCheap I eliminated the need for any other logistics providers.', '优秀的UI界面，非常极简主义并充满功能性。反应迅速的客户服务--执行力强，不做作。自从我改用ShipsCheap后，我就不再需要任何其他物流供应商了。'],
  ReadyToShipNow: ['Ready to ship now?', '准备开始邮寄包裹?'],
  ReadyToShipNowText: ['Access discounts up to 89% off', '立即节省运费高达89% off'],
  subscribeInputTitle: ['Subscribe to our Email newsletter', '订阅我们的电子邮件新闻简报'],
  subscribeInputText: ['Join the 10,000+ people that use ShipsCheap', '立即订阅，掌握最新资讯、产品更新及特惠信息。'],
  subscribeInputPlaceholder: ['Enter Your Email', '输入邮箱地址'],
  subscribe: ['Subscribe', '免费订阅'],
  about: ['About', '关于我们'],
  company: ['Company', '公司介绍'],
  careers: ['Careers', '合作伙伴'],
  comtactUs: ['Comtact Us', '联系我们'],

  support: ['SUPPORT', '服务支持'],
  blogsOrNews: ['Blogs & News', '博客与新闻'],
  helpCenter: ['Help Center', '帮助中心'],

  youDeserveABetterShippingExperience: ['You deserve a better shipping experience.', '您值得拥有更好的邮寄体验'],
  sayGoodbyeToComplicatedProcesses: ['Say goodbye to complicated processes.', '跟复杂的流程说拜拜'],

  aSmarterWayToShip: ['A Smarter Way to Ship', '更智能的邮寄方式'],
  aSmarterWayToShipText1: ['Of course, you could stand in a long line at the post office with a face mask, or ship like a pro with ShipsCheap for Starter.', '当然，您也可以戴着口罩在邮局排长队等待寄包裹，或者通过ShipsCheap简单易操作的系统在家轻松寄件。'],
  aSmarterWayToShipText2: ['Ship from your couch 🛋️ - Get instant access to the best USPS rates up to 89% off.', '只需30秒即可购买运单，即时享受USPS美国邮政高达89%的折扣价格和DHL Express高达76%的折扣价格。'],
  aFreeWayToStart: ['A Free Way to Start', '更轻松的开始方式'],
  aFreeWayToStartText1: ['Save more, stress less. Experience simplicity and efficiency with our user-friendly system.', '使用ShipsCheap是完全免费的。无需月费和预充值，也没有最低运输量的要求 - 你只需为你的运输支付费用。'],
  aFreeWayToStartText2: ["Best of all, it's Free! No subscription fees and minimum shipping volume required. ", '现在注册一个免费账户，享受USPS的深度折扣，友好的客户支持，从你的商店一键导入订单，以及其他邮寄实用功能。'],
  buyTitle: ['buy', '购买'],
  buyText1: ['Save up to 89% off compared to the retail rates', '与零售价相比， 可节省高达89%的邮寄费'],
  buyText2: ["No matter where you're ship from, ShipsCheap will always provide the cheapest rates for you.", '无论您从哪里发货，ShipsCheap都将为您提供最便宜的运费价格。'],
  printTitle: ['print', '打印'],
  printText1: [`Print shipping labels with any types of printers                  `, '支持使用任何类型的打印机来打印折扣运单'],
  printText2: ['You can print labels at home, downland PDF, or save the shareable link for later use.', '你可以在家里打印运单，下载PDF文档，或保存可共享的链接供以后使用。'],
  shipTitle: ['ship', '邮寄'],
  shipText1: ['Put the shipping label on the package, and ship it out.', '在包裹上贴上打印好的运单，然后轻松寄出'],
  shipText2: ['Skip the long line at postal office, drop off the packages or schedule a pickup, for free.', '跳过邮局的长队，把包裹直接投递到邮局或预约上门取件，都是免费的'],
  simplySaveTExt: ["Whether you're a home shipper or just starting your business, ShipsCheap for Starter is the perfect plan to cut off the costs with no subscription fees and monthly fees.", '无论你是个人邮寄还是刚开始您的电商生意，ShipsCheap对您来说都是一个完美的选择，无需订阅费和月费，可以减低成本。'],
  signUpTodayForFree: ['Sign up Today for Free', '免费注册用户'],
  chooseYourPerfectPlan: ['Choose Your Perfect Plan', '选择您的理想价格计划'],
  chooseYourPerfectPlanText: ['Whether shipping a single package or thousands, ShipsCheap offers easy access to exceptional discounts with solutions tailored to your needs.', '无论是发货单个包裹还是数千个，ShipsCheap都为您提供轻松获得的特殊折扣，以及根据您的需求量身定制的解决方案。'],
  starterText: ['Ideal for personal and small-scale shipping, offering the basics without a monthly fee.', '适合希望获得优惠运费的个人和小型公司'],
  monthly: ['monthly', '月'],
  WhatIsIncluded: ["What's included", '版本包含内容'],
  starterIncluded1: ['No monthly subscription fees', '无需月费或其他订阅费'],
  starterIncluded2: ['Easy setup, user-friendly design', '设定简便，界面友好'],
  starterIncluded3: ['Best USPS, UPS & DHL discounts', '获得USPS、UPS的最优折扣'],
  starterIncluded4: ['Import orders from your stores', '可直接从您的店铺同步订单'],
  getStarted: ['Get Started', '立即注册'],
  features: ['Features', '版本功能'],
  eCommerceIntegrations: ['eCommerce Integrations', '电商平台对接'],
  addressAutocompleteOrCorrection: ['Address Autocomplete & Correction', '地址自动填充与更正'],
  linkYourCarrierAccounts: ['Link Your Carrier Accounts', '添加您自己的承运商账号'],
  updatedTrackingStatus: ['Updated Tracking Status', '包裹跟踪状态实时更新'],
  availableUser: ['Available User', '支持用户数'],
  saveCommonlyUsedAddress: ['Save Commonly Used Address', '保存常用地址'],
  paymentMethod: ['Paypal Method', '付款方式'],
  saveCommonlyUsedPackage: ['Save Commonly Used Package', '保存常用包裹设置'],
  multilingualSystem: ['Multilingual System (EN, ZH)', '双语系统 (英文, 中文)'],
  saveShipmentDraftForFurtherUse: ['Save Shipment Draft for further use', '保存运单草稿'],
  bestUspsRates: ['Best USPS Rates', 'USPS, UPS & DHL超低运费折扣'],
  generateUspsLabelBroker: ['Generate USPS Label Broker (QR Code)', '支持生成USPS二维码'],
  accessCommercialShippingServices: ['Access Commercial Shipping Services', '享受商业运输服务和费率'],
  pickupAndReturnManagement: ['Pick-up and Return Management', '上门取件与退货管理'],
  unlimited: ['Unlimited', '无上限'],
  get: ['Get', '支持'],
  payAsYouGo: ['Pay As You Go', '随用随付'],
  ItIsEasyToGetStarted: ["It's easy to get started, and it's free. The two things everybody loves.", '轻松上手，而且完全免费。这正是大家都喜爱的。'],
  ItIsEasyToGetStartedText: ["Use ShipsCheap to fit all your needs, whether you're looking to send one package with discounted shipping rates or need a solution to scale your eCommerce shipping.", '选择符合您需求的价格方案，无论您是想以优惠运费发送单个包裹，还是需要一个方案来扩展您的电商发货。'],
  shipWithinTheUS: ['Ship within the U.S.', '美国境内寄送'],
  zipcode: ['Zipcode' ,'邮编'],
  shipFrom: ['Ship From', '发件地址'],
  shipTo: ['Ship To', '收件地址'],
  packages: ['Packages', '箱子'],
  customizePackage: ['Customize Package', '自定义箱子'],
  dimension: ['Dimension', '包裹尺寸&重量信息'],
  length: ['Length', '长'],
  width: ['Width', '宽'],
  height: ['Height', '高'],
  weight: ['Weight', '重量'],
  getRates: ['Get Rates', '运费查询'],
  getInstantShippingDiscountsTitle: ['Get instant shipping discounts up to 89% off', '随时随地，获得USPS®的即时运费报价'],
  getInstantShippingDiscountsText: ['Use our shipping rate calculator to estimate your savings, then sign up a free ShipsCheap account to buy and print your labels.', '通过ShipsCheap运费计算器，输入包裹详细信息来估算您节省的物流成本，然后注册一个免费的ShipsCheap账户来购买和打印您的运单。'],
  UseOurShippingRateCalculatorToEstimateTitle: ['Empower the best shipping solution at all sizes', '不论您是个人用户或商业用户，ShipsCheap提供个性化解决方案'],
  UseOurShippingRateCalculatorToEstimateText: ["Choosing the right shipping strategy can be tricky.Whether you're sending 1 package or thousands, S will always help you find better shipping rates.", '选择正确的运输策略可能是很棘手的。无论您是发送一个包裹还是数以千计的包裹，ShipsCheap在线折扣打单平台将始终帮助您找到更好的运输费率'],
  frequentlyAskedQuestions: ['Frequently Asked Questions', '常见问题汇总'],
  frequentlyAskedQuestions1: ['What is ShipsCheap’s shipping calculator?', 'ShipsCheap运费计算器是什么?'],
  frequentlyAskedQuestions1Text1: ['Use the ShipsCheap shipping calculator to get a shipping quote and estimated delivery time for your packages. Just enter your package details (dimensions, weight, , and s zip codes)  to find the corresponding discounted rates. Then, to print the labels, sign up for a free ShipsCheap account.', '使用ShipsCheap运费计算器为您的包裹获取运费报价和预计交付时间。只需输入您的包裹详情（尺寸、重量、以及邮政编码），即可找到相应的折扣费率。然后，请注册一个免费的ShipsCheap账户用于运单打印。'],
  frequentlyAskedQuestions1Text2: ['Our free shipping calculator gives you accurate instant quotes for the best shipping rates from USPS. 100% free and accurate.', '我们的免费运输计算器为您提供准确的即时运费报价，USPS 的最佳运输费率。100%免费且准确。'],
  frequentlyAskedQuestions1Text3line1: ['Shipping cost estimates do not include duties and taxes.', '运费计算器生成的运费不包括海关关税和其它税款。'],
  frequentlyAskedQuestions1Text3line2: ['Delivery times are estimates and times may vary.', '包裹交付时间是预估的，时间可能会因为承运商服务限制有所不同。'],
  frequentlyAskedQuestions1Text3line3: ['Delivery times do not include time to pass through customs. Each country is different. Please contact your local customs office for an accurate estimate.', '包裹交付时间不包括通关检查的时间。每个国家通关要求不同，请联系您当地的海关办公室以获得准确的预估。'],
  frequentlyAskedQuestions2: ['Is shipping based on size or weight?', '运输费率是基于尺寸还是重量？'],
  frequentlyAskedQuestions2Text1: ['When sending a package, you must have had such confusion. Why the actual weight of the package is 10 lbs, but the final shipping cost is calculated by 15 lbs?', '在发送包裹时，你一定有过这样的困惑。为什么包裹的实际重量是10磅，但最后的运费却按15磅计算？'],
  frequentlyAskedQuestions2Text2: ['This is because all major carriers use a pricing technique called dimensional weight (also called DIM weight) to calculate shipping rates for larger and lighter packages to help cover the cost of storage space in transit.', '这是因为所有大多数承运商都使用一种称为 体积重 （也称为DIM重量）的定价技术来计算较大和较轻包裹的运费，以帮助支付运输中的存储空间成本。'],
  frequentlyAskedQuestions3: ['How does package pickup work?', '如何预约上门取件服务？'],
  frequentlyAskedQuestions3Text1: ['USPS pickup service, you need to create or sign in to a USPS account, then in just four steps, you can schedule a package pickup for free.', '美国邮政 取件服务，您需要创建或登录一个 USPS账户 ，然后只需四个步骤，您就可以免费安排取件。首选，输入您的取件地点，USPS将验证地址是否有取件资格。接下来将包裹放在您的邮差可以到达的安全位置，并选择取件时间就大功告成啦。'],
  frequentlyAskedQuestions3Text2: ['For DHL Express pickup service, you can simply enter your waybill number to schedule without the account needed.', '好消息是，您可以直接在ShipsCheap系统中快速预约USPS上门取件，无需注册USPS账号。'],
  frequentlyAskedQuestions4: ['Do I have to be a business to use ShipsCheap?', '个人寄件可以使用ShipsCheap吗?'],
  frequentlyAskedQuestions4Text1: ['', '答案是肯定的!'],
  frequentlyAskedQuestions4Text2: ['The answer is NO! You do not have to be a business to use ShipsCheap.', '您不需要成为一个电商企业才能使用ShipsCheap。任何人都可以在ShipsCheap平台免费注册一个账户!您可以使用我们的平台来同时满足您的个人和商业运输需求。'],
  frequentlyAskedQuestions4Text3:[' Everyone can sign up for a free account at ShipsCheap platform! You can use our platform for both your personal and business shipping demands.', '对比各大承运商运费，在线购买和打印运单，完成整个寄送流程。ShipsCheap自动地址校正功能，让您无需再为地址信息是否符合规定而担心。同时，您可以在ShipsCheap系统中直接为您的国际包裹填写报关信息，跟复杂的表格说拜拜'],
  partnersTitle: ['Empower Your Business with ShipsCheap Partners', '通过ShipsCheap合作伙伴赋能您的业务'],
  partnersText: ['Discover the exceptional companies that collaborate with ShipsCheap to revolutionize logistics solutions.', '探索与ShipsCheap合作的卓越公司，共同革新物流解决方案。'],
  carrierPartner: ['Carrier', '承运商'],
  carrierPartnerText:['Save time by accessing the many services of the United States Postal Service at USPS.', '在线打印USPS运单，享受超高运费折扣，每单领返现'],
  ecommercePartner: ['Ecommerce', '电商平台'],
  ecommercePartnerText: ['ShipsCheap turns your Shopify store orders into discounted shipping labels!', '在线打印USPS运单，享受超高运费折扣，每单领返现'],
  more: ['more', '了解更多'],
  becomeAPartners: ['Become a Partners', '为什么要与ShipsCheap合作'],
  becomeAPartnersText: ["We've rounded up best practices from our partners to help you build new experiences.", '我们从合作伙伴中总结了最佳的实践方案，帮助您建立新的体验，更快启动'],
  businessGrowth: ['Business Growth', '业务支持'],
  businessGrowthText: ['Get ShipsCheap program support and business opportunity referrals to help your services & products.', '获取ShipsCheap专业的项目支持和商机推荐以帮助您升级服务。同时我们也会提供行业活动等丰富的营销机会。'],
  technologyOrTool: ['Technology & Tool', '技术支持'],
  technologyOrToolText: ["Take advantage of ShipsCheap's flexible API integration to quickly build new product experiences.", '打通电商平台的打单物流功能可能是一件耗时的复杂工作。利用ShipsCheap专业API，可以快速构建新的产品体验。'],
  jointMarketing: ['Joint Marketing', '市场支持'],
  jointMarketingText: ["We've put together resources that you can use directly to help your team grow fastly and smoothly.", '利用联名材料推广您的集成应用。我们已经整理了可供您直接使用的资源来帮助您的团队和客户。'],

  aboutusTitle: ['Navigating the Future of Shipping with ShipsCheap', '与ShipsCheap共同探索运输的未来'],
  aboutusText: ['Embark on a journey with us to explore innovative and efficient shipping solutions. With ShipsCheap, every shipment is a promise of excellence. ', '随我们踏上这段探索之旅，发掘最具创新力和高效的运输解决策略。使用ShipsCheap系统，每一次的发货不仅仅是简单的寄送，它代表了对卓越品质的追求与承诺'],
  welcomeToShipsCheap: ["Welcome to ShipsCheap. We're not just another name in the shipping world; we’re the dream team turning shipping puzzles into a piece of cake.", '欢迎加入ShipsCheap的大家庭。我们不仅仅是运输领域中的另一个品牌，我们是那支将发货难题轻而易举化解的梦之队。'],
  allAboutShipsCheap: ['ALL ABOUT ShipsCheap', '关于ShipsCheap'],
  allAboutShipsCheapText: ['Dive Deep into Our Core', '深入了解我们的核心价值'],
  knowledgeBuffet: ['Knowledge Buffet', '学无止境'],
  knowledgeBuffetText: ['We’ve explored, learned, and excelled. Trust us as your shipping gurus!', '我们经过严格的探索和学习，现在成为了货运的专家。'],
  teamFantastic: ['Team Fantastic', '团队实力'],
  teamFantasticText: ['Meet our heroes. No capes, just unmatched passion for smooth shipping.', '这里有一群热情的人，他们致力于为您提供最好的货运体验。'],
  usaOrBeyond: ['USA & Beyond', '遍布全球'],
  usaOrBeyondText: ['NYC, California, or abroad? We ensure shipments travel seamlessly.', '不论您在纽约、加州还是国外，我们都能保证货物顺利到达。'],
  hereForTheChats: ['Here for the Chats', '我们在倾听'],
  hereForTheChatsText: ['Every story, feedback we listen, care, and adapt. Shipping with a heartbeat.', '无论您有什么故事或反馈，我们都会认真听取并持续改进。'],
  digitalPlayground: ['Digital Playground', '更多于一个工具'],
  digitalPlaygroundText: ["More than a tool - it's a community. Dive in, connect, ship. As easy as 1, 2, 3.", 'ShipsCheap不只是一个工具，更是一个供您交流和连接的平台。'],
  foreverForward: ['Forever Forward', '始终前进'],
  foreverForwardText: ['Not stuck in the past. We’re shaping the future of shipping, always.', '我们始终致力于创新，为货运的未来不断努力。'],
  FAQs: ['FAQs' ,'ShipsCheap常见问题汇总'],
  FAQsQuestion1: ['Who needs ShipsCheap?', '谁需要ShipsCheap?'],
  FAQsQuestion1Answer1: ['ShipsCheap is for everyone who has shipping needs. Whether you own an e-commerce business or you are just on yourself to ship packages, ShipsCheap is a great web-based platform for you to save shipping costs!', '无论您是电商业务的拥有者，还是个人需要发送包裹，ShipsCheap都是您的理想选择，帮助您节省货运费用的线上平台！'],
  FAQsQuestion1Answer2: ['‍Let’s register an account to kick off shipping discounts of up to 89% off. ', '立即注册账号，享受高达89%的货运折扣。'],
  FAQsQuestion2: ['Does ShipsCheap have a free version? ', 'ShipsCheap有免费版本吗？'],
  FAQsQuestion2Answer1: ['Yes, ShipsCheap offers a free version called ShipsCheap for Starter to empower people who have shipping needs. ShipsCheap does not want to ship packages that are pricy; we would like to minimize shipping costs for all.', '是的，ShipsCheap提供一个叫做“ShipsCheap for Starter”的免费版本，旨在满足人们的货运需求。我们不希望客户因高昂的价格而受阻，我们的目标是为所有人降低货运成本。'],
  FAQsQuestion2Answer2: ['However, if your shipping needs are high, ShipsCheap also offers paid Standard and Pro plans to cater to your needs.', '但是，如果您的货运需求较大，ShipsCheap还提供收费的标准版和专业版以满足您的需求'],
  FAQsQuestion3: ['How does ShipsCheap calulate the best rates?', 'ShipsCheap如何计算最优惠的运费价格？'],
  FAQsQuestion3Answer1: ['ShipsCheap uses a combination of real-time data, advanced algorithms, and partnerships with major domestic and international shipping carriers to provide users with the most competitive rates.', 'ShipsCheap结合实时数据、先进的算法以及与主要国内和国际货运承运商的合作伙伴关系，为用户提供最具竞争力的价格。我们持续更新系统以反映最新的货运费用和促销活动。'],
  FAQsQuestion3Answer2: ["We constantly update our system to reflect the latest shipping costs and promotions. Whether you're shipping within the country or internationally, our platform ensures that users always get the best deals and seamless shipping processes. ", '无论您是在国内发货还是国际发货，我们的平台都确保用户始终获得最佳的交易和无缝的货运过程。'],
  stillHaveQuestions: ['Still have questions?', '更多问题？'],
  contactUs: ['Contact us', '请联系我们'],
  howCanWeHelp: ['How can we help?', '有什么可以帮到你呢'],
  GetStarted: ['Get Started' ,'操作指南'],
  GetStartedText: ['New to ShipsCheap? Here are a few tips before you dive into our system.', 'ShipsCheap新手？在您深入了解我们的系统之前，以下是一些提示。'],
  StoreIntegration: ['Store Integration', '商店集成'],
  StoreIntegrationText: ['Connect your online stores in minutes, and start syncing orders right away.', '几分钟后连接您的在线商店，并立即开始同步订单'],
  CarrierGuide: ['Carrier Guide', '载体指南'],
  CarrierGuideText: ['Everything you need to know about carriers restrictions & surcharges.', '您需要了解的有关承运人限制和附加费的所有信息。'],
  CreateShipments: ['Create Shipments', '创建发货'],
  CreateShipmentsText: ['Create shipments, and file claims for lost or damaged packages.', '创建发货，并对丢失或损坏的包裹提出索赔。'],

  ManageInventory: ['Manage Inventory', '管理库存'],
  ManageInventoryText: ['Manage inventory with our free WMS barcode system and picking process.', '使用我们免费的WMS条形码系统和拣货流程管理库存。'],
  RefundOrClaims: ['Refund & Claims', '退款和索赔'],
  RefundOrClaimsText: ['Create shipments, and file claims for lost or damaged packages.' ,'创建发货，并对丢失或损坏的包裹提出索赔。'],
  latestUpdates: ['Latest Updates' ,'最新更新'],
  latestUpdatesText: ['Get ShipsCheap updates for new features, and announcements.' ,'获取新功能和公告的ShipsCheap更新。'],
  GeneralFAQ: ['General FAQ', '常见问题解答'],
  GeneralFAQText: ['See the most common topics about how to start with ShipsCheap. ', '请参阅有关如何开始使用ShipsCheap的最常见主题。'],

  enterpriseLevelConsulting: ["Enterprise-level Consulting", '企业级咨询'], 
  enterpriseLevelConsultingText: ['Providing one-on-one services such as pre-consultation, logistics solution recommendations, and freight quoting to enterprise-level customers, to help you smoothly process orders and ship without worries!', '为企业级用户提供前期咨询、物流解决方案推荐、运费报价等一对一服务， 助您打单顺畅，发货无忧！'],
  advertisingCampaign: ['Advertising Campaign', '广告推广'], 
  advertisingCampaignText: ['Display your brand advertisements in various forms on our website, social media platforms, and offline events to achieve mutual success and cooperation.', '将你的品牌广告在我们的网站、社交媒体平台、线下活动以多样形式展现，实现合作共赢。'], 
  businessCooperation: ['Business Cooperation', '商务合作'],
  businessCooperationText: ['ShipsCheap is seeking merchants with business channels in the United States who are interested in deep cooperation for negotiation. Please feel free to contact us.', 'ShipsCheap正在寻找美国有业务渠道洽谈合作意向的商家进行深度合作，欢迎联系。'],
  getInTouch: ['Get in Touch', '欢迎留言'],
  Name: ['Name', '姓名'],
  Phone: ['Phone', '联系电话'],
  Email: ['Email', '联系邮箱'],
  LeaveMessage:['Leave Message', '请留言'],
  submit: ['Submit', '确认提交'],

  phoneInputError: ['Please fill in the correct phone', '请填写正确的电话号码'],
  emailInputError: ['Please provide the correct email', '请填写正确的电子邮箱'],
  zipCodeInputError:['Please fill in the correct zip code', '请正确填写邮编'],
  numberInputError: ['Please fill in the correct numerical value', '请填写正确的数值'],
  sizeLimit: ['Length ≤ 30 inches; Length+(height+width) * 2 ≤ 130 inches; At least one area not less than 5.91 inches * 3.94 inches', '长≦30 inches; 长+（高+宽）*2 ≦ 130 inches;至少有一个面积不小于5.90 inches*3.94 inches'],
  overweightErr: ['Exceeding the limit weight(10lb)', '超出最大重量(10lb)'],
  required: ['Required', '必填'],
  businessDays: ['business days', '个工作日'],
  UPSGroundSaverText: ['It is an economical shipping service for your non-urgent packages.', '是一种为您的非紧急包裹提供的经济邮寄服务。'],
  SubscriptionSuccessfulSText: ['Subscription successful, thank you', '订阅成功，谢谢'],
  
}
const info = {
  ZH: {},
  EN: {}
}
for (const key in text) {
  info.ZH[key] = text[key][1]
  info.EN[key] = text[key][0]
}
export default info
