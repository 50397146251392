import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home.vue'




const routes = [
  {
    path: '',
    name: '',
    redirect: '/home',
    component: Home,
    children: [
      {
        path: 'home',
        name: '首页',
        component: () => import(/* webpackChunkName: "about" */ '@/page/home.vue')
      },
      {
        path: 'solutions',
        name: '解决方案',
        component: () => import(/* webpackChunkName: "about" */ '@/page/solutions.vue')
      },
      {
        path: 'pricing',
        name: '价格方案',
        redirect: '/pricing',
        children:[
          {
            path: '',
            name: '免费使用',
            component: () => import(/* webpackChunkName: "about" */ '@/page/pricing.vue')
          },
          {
            path: 'rate-calculator',
            name: '计费器',
            component: () => import(/* webpackChunkName: "about" */ '@/page/rateCalculator.vue')
          },
        ]
      },
      {
        path: 'partners',
        name: '合作伙伴',
        component: () => import(/* webpackChunkName: "about" */ '@/page/partners.vue')
      },
      {
        path: 'aboutus',
        name: '公司介绍',
        component: () => import(/* webpackChunkName: "about" */ '@/page/aboutus.vue')
      },
      {
        path: 'contactus',
        name: '联系我们',
        component: () => import(/* webpackChunkName: "about" */ '@/page/contactus.vue')
      },
      {
        path: 'service',
        name: '帮助中心',
        component: () => import(/* webpackChunkName: "about" */ '@/page/service.vue')
      }
      
      
      
    ]
  }
  // { path: '/:catchAll(.*)', component: NotFound }
]
const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes
})
export default router
