<template>

  <router-view/>
</template>

<script setup>
const themeColorOverrides = {
  '--el-color-primary': '#ff850d',
  '--global-normal-color': '#ff850d',
  '--el-color-primary-dark-2': '#ff9c39',
  '--el-color-primary-light-3': '#ffa953',
  '--el-color-primary-light-5': '#ffb367',
  '--el-color-primary-light-7': '#ffbf80',
  '--el-color-primary-light-8': '#ffce9c',
  '--el-color-primary-light-9': '#ffd7b0'
  // ... 其他自定义颜色变量
}
Object.keys(themeColorOverrides).forEach((key) => {
  document.body.style.setProperty(key, themeColorOverrides[key])
})
</script>

<style>
*{
  padding: 0;
  margin: 0;
}
#app {
  /* font-family: ; */
  font-family: Arial,sans-serif;
  /* min-width: 100vw; */
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
</style>
