<template>
  <div class="headBar" @mouseleave="navigationShow = ''">
    <div class="logo">
      <img @click="toPage('/home')" src="@/assets/img/logo.png" alt="">
    </div>
    <div class="navlist">
      <!-- , route.path.includes('solutions') && 'active' -->
        <div :class="['navItem']" @mousemove="navigationShow = 'solutions'">
          <span>{{ $t('solution').toUpperCase() }}</span>
          <el-icon class="icon">
            <ArrowUp v-if="navigationShow === 'solutions'" />
            <ArrowDown v-else />
          </el-icon>
        </div>
        <div :class="['navItem']" @mousemove="navigationShow = 'pricing'">
          <span>{{ $t('pricingPlan').toUpperCase() }}</span>
        <el-icon class="icon">
          <ArrowUp v-if="navigationShow === 'pricing'" />
            <ArrowDown v-else />
        </el-icon>
        </div>
        <div :class="['navItem']" @mousemove="navigationShow = 'partners'">
          <span>{{ $t('partner').toUpperCase() }}</span>
          <el-icon class="icon">
            <ArrowUp v-if="navigationShow === 'partners'" />
            <ArrowDown v-else />
          </el-icon>
        </div>
      <!-- </ul> -->
    </div>
    <div class="btnBar">
      <button @click="toLogin()">{{ $t('Login') }}</button>
      <button @click="toRegister()">{{ $t('SignUpForFree') }}</button>
      <div class="language">
        <!-- $i18n.locale = 'zh' -->
        <span :class="$i18n.locale === 'en' && 'active'" @click="() => {changeLanguage('en')}">EN</span>
        <span :class="$i18n.locale === 'zh' && 'active'" @click="() => {changeLanguage('zh')}">中文</span>
      </div>
    </div>
    <div class="menu-btn">
      <el-icon class="icon" @click="menuBoxShow = !menuBoxShow"><Menu /></el-icon>
    </div>
      <div class="menu-box" v-if="menuBoxShow">
        <ul>
          <li><p style="text-align: center;"><a @click="toPage('/home')">{{ $t('Home').toUpperCase() }}</a></p></li>
          <li>
            <p><b>{{ $t('solution').toUpperCase() }}</b></p>
            <p><a @click="toPage('/solutions')">{{ $t('ForStartupsTitle') }}</a></p>
          </li>
          <li>
            <p><b>{{ $t('PricingPlanTitle').toUpperCase() }}</b></p>
            <p><a @click="toPage('/pricing')">{{ $t('PricingPlanText1') }}</a></p>
            <p><a @click="toPage('/pricing/rate-calculator')">{{ $t('RateCalculatorTitle') }}</a></p>
          </li>
          <li>
            <p><b>{{ $t('partner').toUpperCase() }}</b></p>
            <p><a @click="toPage('/partners')">{{ $t('CarrierPartnetTitle') }}</a></p>
            <p><a @click="toPage('/partners')">{{ $t('EcommercePartnerTitle') }}</a></p>
          </li>
        </ul>
        <p class="menu-btnBar">
          <button @click="toLogin()">{{ $t('Login') }}</button>
          <button @click="toRegister()">{{ $t('SignUpForFree') }}</button>
        </p>
        <br>
      </div>
    <div class="navigation" v-if="navigationShow">
      <div class="column" v-if="navigationShow === 'solutions'">
        <div :class="['content']" @click="toPage('/solutions')">
          <ul>
            <li>{{ $t('ForStartupsTitle') }}</li>
            <li>{{ $t('ForStartupsText1') }}</li>
            <li>{{ $t('ForStartupsText2') }}</li>
            <li>{{ $t('ForStartupsText3') }}<span class="text-span">POPULAR</span></li>
            <li>{{ $t('ForStartupsText4') }}</li>
          </ul>
        </div>

      </div>
      <div class="column" v-else-if="navigationShow === 'pricing'">
        <div :class="['content']" @click="toPage('/pricing')">
          <ul>
            <li>{{ $t('PricingPlanTitle') }}</li>
            <li>{{ $t('PricingPlanText1') }}<span class="text-span">HOT</span></li>
          </ul>
        </div>
        <div :class="['content']" @click="toPage('/pricing/rate-calculator')">
          <ul>
            <li>{{ $t('RateCalculatorTitle') }}</li>
            <li>{{ $t('RateCalculatorText1') }}<span class="text-span">HOT</span></li>
          </ul>
        </div>
      </div>
      <div class="column" v-else-if="navigationShow === 'partners'">
        <div :class="['content']" @click="toPage('/partners')">
          <ul>
            <li>{{ $t('CarrierPartnetTitle') }}</li>
            <li>{{ $t('CarrierPartnetText1') }}</li>
          </ul>
        </div>
        <div :class="['content']" @click="toPage('/partners')">
          <ul>
            <li>{{ $t('EcommercePartnerTitle') }}</li>
            <li>{{ $t('EcommercePartnerText1') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
const navigationShow = ref('')
const router = useRouter()
const route = useRoute()
const menuBoxShow = ref(false)
// const routes = router.getRoutes()
// console.log('routes', routes)
function toPage (path) {
  console.log(route.path)
  navigationShow.value = ''
  menuBoxShow.value = false
  window.scrollTo(0, 0)
  router.push(path)
}
function toRegister () {
    window.location.href = 'https://ship.shipscheap.com/login/register'
}
function toLogin () {
    window.location.href = 'https://ship.shipscheap.com/login/index'
}
function changeLanguage (lang) {
  localStorage.setItem('userLang', lang)
  setTimeout(() => {
    location.reload()
  }, 100)
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.menu-box::-webkit-scrollbar {
  background: rgba(0, 0, 0, .1) !important;
  border-radius: 10px !important;
  width: 0px;
  height: 0;
}

.menu-box::-webkit-scrollba {
  background: rgba(0, 0, 0, .1) !important;
  border-radius: 10px !important;
  width: 0px;
  height: 0;
}
.headBar{
  z-index: 100;
  border-bottom: .0625rem solid #f1f2f3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // max-width: 100vw;
  // min-width: 100vh;
  // padding: 0px 5rem;
  height: 6.5625rem;
  backdrop-filter: blur(.9375rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 3.75rem 0px 5rem;
  @media (max-width: 1000px){
    padding: 0px 1.25rem 0px 1.25rem;
    height: 5rem;
    background-color: #ffffff;
  }
  .logo{
    // background-color: #fff;
    cursor: pointer;
    width: 10rem;
    height: 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      display: block;
      width: 100%;
      // background-color: #ff860d;
      
    }
    // margin-right: 5rem;
    @media (max-width: 1000px) {
      // margin-right: 1.875rem;
    }
    @media (max-width: 355px){
      width: 7.5rem;
    }
  }
  .navlist{
    flex: 1;
    font-size: 1rem;
    color: #292929;
    display: flex;
    align-items: center;
    padding-left: 3%;
    padding-right: 1.25rem;
    max-width: 60%;
    box-sizing: border-box;
    @media (max-width: 1000px) {
      display: none;
    }
    .navItem{
      list-style: none;
      margin-right: 2.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
      .icon{
        margin-left: .5625rem;
        font-size: .875rem;
        font-weight: 600;
      }
    }
    &>.active{
      color: #ff860d;
      font-weight: 600;
    }
  }
  .menu-btn{
    display: none;
    font-size: 1.875rem;
    cursor: pointer;
    .icon{
      font-weight: 800;
    }
    @media (max-width: 1000px) {
      display: flex;
    }
  }
  
  .btnBar{
    // background: #FFC318;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    
    button{
      border: none;
      border-radius: 3.125rem;
      cursor: pointer;
      box-sizing: border-box;
      font-size: 1rem;
      &:hover{
        opacity: .9;
      }
      &:nth-child(1){
        color: #292929;
        background-color: transparent;
        font-weight: 600;
        margin-right: 2.5rem;
      }
      &:nth-child(2){
        padding: .5rem 1rem;
        color: #ffffff;
        background: #292929;
        box-shadow: 0px .375rem .625rem 0px rgba(41,41,41,0.3);
        margin-right: 1.25rem;
      }
    }
    .language{
      min-width: 5rem;
      font-size: 1rem;
      span{
        border-bottom: .125rem;
        padding-bottom: .375rem;
        margin: 0px .25rem;
        cursor: pointer;
        color: #9aa2ad;
      }
      .active{
        border-bottom: #ff860d .125rem solid;
        color: #292929;
      }
    }
    @media (max-width: 1150px){
      button{
        &:nth-child(1){
          margin-right: 1.5625rem;
        }
        
      }
    }
    @media (max-width: 1000px) {
      button{
        display: none;
      }
      .language{
        min-width: 3.125rem;
        font-size: 1.375rem;
        span{
          margin: 0px .5rem;
        }
      }
    }
    @media (max-width: 355px){
      .language{
        min-width: 1.875rem;
        font-size: 1rem;
        span{
          margin: 0px .25rem;
        }
      }
    }
  }
  
  .menu-box{
    display: none;
    position: absolute;
    width: 100%;
    top: 5rem;
    left: 0;
    // min-height: 6.5625rem;
    background-color: #ffffff;
    padding: .625rem;
    box-sizing: border-box;
    overflow-y: auto;
    height: calc(100vh - 5rem);
    ul{
      padding: 0;
      li{
        list-style: none;
        font-size: 1.125rem;
        p{
          margin-bottom: .3125rem;
          box-sizing: border-box;
          b{
            display: inline-block;
            padding: .625rem .3125rem;
            box-sizing: border-box;

          }
          a{
            cursor: pointer;
            width: 100%;
            padding: .625rem .9375rem;
            box-sizing: border-box;
            display: inline-block;
            border-radius: .375rem;
            border: .0625rem solid #dddfe2;
            &:active{
              border-color: #ff860d;
              color: #ff860d;
            }
          }
        }
      }
    }
    .menu-btnBar{
      padding:1.25rem .625rem ;
      display: flex;
      justify-content: center;
      @media (max-width: 325px){
        flex-direction: column;
        button{
          margin: .375rem 0px;
        }

      }
      button {
        border: none;
        border-radius: 3.125rem;
        cursor: pointer;
        box-sizing: border-box;
        font-size: 1rem;
        &:hover{
          opacity: .9;
        }
        &:nth-child(1){
          color: #292929;
          background-color: transparent;
          font-weight: 600;
          margin-right: 2.5rem;
        }
        &:nth-child(2){
          padding: .5rem 1rem;
          color: #ffffff;
          background: #292929;
          box-shadow: 0px .375rem .625rem 0px rgba(41,41,41,0.3);
          margin-right: 1.25rem;
        }
      }
    }
    @media (max-width: 1000px){
      display: block;
    }
   
  }
  .navigation{
    position: absolute;
    width: 100%;
    top: 6.5625rem;
    left: 0;
    min-height: 6.5625rem;
    background-color: rgba(255,255,255,0.9);
    backdrop-filter: blur(.9375rem);
    padding: .625rem 0 ;
    padding-left: 20%;
    @media (max-width: 1140px){
      padding-left: 1.25rem;
    }
    @media (max-width: 1000px){
      display: none;
    }
    .column{
      display: flex;
      .content{
        margin-right: 1.875rem;
        padding: 1.25rem 2.5rem;
        border-radius: .75rem;
        cursor: pointer;
        width: 20rem;
        &:hover{
          background: rgba(255,134,13,0.06);
        }
        .text-span{
          background: #FFC318;
          border-radius: 1.0625rem;
          font-size: .75rem;
          padding: .0625rem .5rem;
          margin-left: .25rem;
          font-weight: 600;
        }
        ul{
          li{
            list-style: none;
            font-size: .875rem;
            line-height: 2.1875rem;

            &:nth-child(1){
              font-weight: 600; 
              line-height: 1.5625rem;
              font-size: 1.125rem;
            }
          }
        }
      }
      &>.active{
        background: #fff8f1;
      }
    }
  }
}
</style>
