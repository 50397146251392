import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'swiper/css'

import { createI18n } from 'vue-i18n'
import EN from './locales/en.js'
import ZH from './locales/zh.js'
const userLang = localStorage.getItem('userLang')

const i18n = createI18n({
  globalInjection: true,
  locale: userLang || 'en', // 默认语言
  messages: {
    en: EN, // 英文语言文件
    zh: ZH // 中文语言文件
  }
})
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.config.globalProperties.$t = i18n.global.t
app.use(router).use(i18n).use(ElementPlus).mount('#app')
